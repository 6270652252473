<script lang="jsx">
import ColorV2Radio from 'public/src/pages/goods_detail_v2/innerComponents/top/MainSaleAttr/ColorV2Radio.vue'
import ColorV2Desc from 'public/src/pages/goods_detail_v2/innerComponents/top/MainSaleAttr/ColorV2Desc.vue'
import ColorV2Shade from 'public/src/pages/goods_detail_v2/innerComponents/top/MainSaleAttr/ColorV2Shade.vue'


export default {
  name: 'ColorV2',
  functional: true,
  components: {
    ColorV2Radio,
    ColorV2Desc,
  },
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    lazyImg: {
      type: String,
      default: ''
    },
    colorConf: {
      type: Object,
      default: () => ({})
    },
    mainGoodsId: {
      type: String,
      default: '',
    },
    mainSaleAttrDescText: {
      type: String,
      default: ''
    },
    eventCategory: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    },
    switchColor: {
      type: Function,
      default: () => {}
    },
    colorType: {
      type: String,
      default: ''
    },
    colorsInfo: {
      type: Array,
      default: () => []
    },
    reviewLocation: {
      type: String,
      default: ''
    },
    promotionTagInfos: {
      type: Object,
      default: () => ({})
    },
    labelExpose: {
      type: Function,
      default: () => {}
    },
    largeImageMode: {
      type: Boolean,
      default: false
    },
    supportLargeImageList: {
      type: Boolean,
      default: false
    },
    goodsDescState: {
      type: Object,
      default: () => {}
    },
    mainAttrPicInfo: {
      type: Object,
      default: () => {}
    },
    skcPreposeVal: {
      type: String,
      default: ''
    },
    preposeColorType: {
      type: String,
      default: 'Thumbnail'
    },
    showGoodsDesc: {
      type: Boolean,
      default: false
    },
    isRw: {
      type: Boolean,
      default: false
    },
    publicCdn: {
      type: String,
      default: ''
    },
    loadConf: {
      type: Object,
      default: () => ({
        size: '750x',
        num: 1
      })
    },
    fixedRatio: {
      type: String,
      default: ''
    },
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    /**
     * 加载状态
     */
    loading: {
      type: Boolean
    },
    /**
     * 最后点击的色块
     */
    lastClickColor: {
      type: String,
      default: ''
    },
    showFindMyShadeEntry: {
      type: Boolean,
      default: false
    },
    clickShowFindMyShade: {
      type: Function,
      default: () => {},
    },
    analysisInfo: {
      type: Object,
      default: () => ({})
    },
    isSkcPrepose: {
      type: Boolean,
      default: false
    },
    isShowAttrNewIcon: {
      type: Boolean,
      default: false
    },
    showColorsTag: {
      type: Boolean,
      default: false
    },
  },
  render(h, { props }) {

    const {
      language,
      lazyImg,
      colorConf,
      mainGoodsId,
      mainSaleAttrDescText,
      eventCategory,
      from,
      switchColor,
      colorType,
      colorsInfo,
      reviewLocation,
      promotionTagInfos,
      labelExpose,
      largeImageMode,
      supportLargeImageList,
      skcPreposeVal,
      preposeColorType,
      showGoodsDesc,
      isRw,
      publicCdn,
      loadConf,
      fixedRatio,
      isSupportCropImage,
      loading,
      lastClickColor,
      mainAttrPicInfo,
      showFindMyShadeEntry,
      clickShowFindMyShade,
      analysisInfo,
      isSkcPrepose,
      isShowAttrNewIcon,
      showColorsTag,
    } = props

    const commonColorListShow = supportLargeImageList ? !largeImageMode : true
    
    return (
      <div class="goods-color__list-box new-bff_colorv2">
        {/* 色块部分 */}
        {commonColorListShow ? <ColorV2Radio
          colorType={colorType}
          colorsInfo={colorsInfo}
          mainGoodsId={mainGoodsId}
          eventCategory={eventCategory}
          reviewLocation={reviewLocation}
          skcPreposeVal={skcPreposeVal}
          preposeColorType={preposeColorType}
          from={from}
          colorConf={colorConf}
          promotionTagInfos={promotionTagInfos}
          labelExpose={labelExpose}
          switchColor={switchColor}
          loadConf={loadConf}
          fixedRatio={fixedRatio}
          isSupportCropImage={isSupportCropImage}
          loading={loading}
          lastClickColor={lastClickColor}
          supportLargeImageList={supportLargeImageList}
          isSkcPrepose={isSkcPrepose}
          analysisInfo={analysisInfo}
          lazyImg={lazyImg}
          isShowAttrNewIcon={isShowAttrNewIcon}
          showColorsTag={showColorsTag}
          language={language}
        /> : null}
        {/* TODO: 大图 + 促销信息 + 促销tag加车弹窗要迁移 */}
        {!isSkcPrepose ? 
          <ColorV2Desc
            mainSaleAttrDescText={mainSaleAttrDescText}
            language={language}
            mainAttrPicInfo={mainAttrPicInfo}
            maxRetry={30}
            from={from}
            goodsId={mainGoodsId}
          /> : null}
        {!!showFindMyShadeEntry && <ColorV2Shade language={language} clickShowFindMyShade={clickShowFindMyShade} />}
      </div>
    )
  }
}
</script>

<style lang="less">
.goods-color {
  color: #222;
  &__wrap {
    a {
      color: inherit;
      text-decoration: unset;
    }
    li {
      position: relative;
    }
  }
  &__imgs {
    padding: 0.15rem 0 0.3733rem;
    font-size: 0;
    .flexbox();
    flex-flow: row nowrap;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__img-item {
    position: relative;
    flex: 0 0 1.12rem;
    width: 1.12rem;
    border: 2px solid transparent;
    .margin-r(0.32rem);
    &.color-active {
      border: 1px solid #222;
      padding: 0.053333rem;
      /*rw:begin*/
      border: 1px solid #ff696e;
      .goods-color__soldout {
        i {
          transform: translate3d(0, 0, 0) rotate(-53.7deg) scale(0.5);
        }
      }
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0 /*rtl:ignore*/;
        bottom: 0;
        right: 0 /*rtl:ignore*/;
        border: 1px solid rgba(34, 34, 34, 0.08);
      }
    }
    .goods-color__soldout {
      i {
        width: 3.8rem;
        transform: translate3d(0, 0, 0) rotate(-52.8deg) scale(0.5);
      }
    }
    .goods-color__new{
      right: -0.37333rem;
    }
  }
  &__text {
    margin-bottom: 0.1067rem;
    margin-top: 0.15rem;
    min-height: 0.96rem;
    .color-active {
      .border-dpr(border, 2px, #222);
      color: #222;
      /* rw:begin */
      .border-dpr(border, 2px, #ff696e);
      color: #ff696e;
    }
  }
  &__text-item {
    display: inline-block;
    max-width: 100%;
    padding: 0.16rem 0.333333rem 0.1333rem 0.333333rem;
    .margin-r(0.32rem);
    margin-bottom: 0.213333rem;
    .border-dpr(border, 2px, #e5e5e5);
    border-radius: 1.28rem;
    line-height: 0.4533rem;
    .font-dpr(28px);
  }
  &__prepose {
    margin-top: 0.266rem;
    .flexbox();
    flex-flow: row nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    &.no-right-arrow {
      padding-right: 0;
    }
  }
  &__prepose-item {
    display: flex;
    align-items: center;
    .margin-r(0.16rem);
    .font-dpr(24px);
    border-radius: 2px;
    color: #000;
    .goods-color__hot {
      .right(-0.0987rem);
      width: 0.6667rem;
      height: 0.2667rem;
    }
    .goods-color__soldout {
      i {
        width: 3.8rem;
      }
    }
    .goods-color__new{
      .right(-0.0987rem);
    }
    &.color-active {
      border: 1px solid #000;
      padding: 0 0.213rem 0 0.106rem;
      .skc-prepose-container__img {
        width: 0.853rem;
        height: 0.853rem;
        border-radius: 1px;
        margin-right: 0.213rem;
      }
      .skc-prepose-container__text {
        display: inline;
      }

      .goods-color__tag {
        top: -0.08rem;
      }
    }
    .goods-color__thickening {
      .right(-0.0787rem);
    }
    .goods-color__tag {
      top: -0.04rem;
    }
  }
  &__prepose {
    .goods-color__prepose-img-c {
      .skc-prepose-container__img {
        margin-right: 0;
        width: 1.01rem;
        height: 1.01rem;
        border: 0.0533rem solid rgba(0, 0, 0, 0.06);
      }
      &.color-active {
        padding: 0;
        .skc-prepose-container__img {
          margin-right: 0;
          width: 0.96rem;
          height: 0.96rem;
        }
        .skc-prepose-container__text {
          display: none;
        }
        .skc-prepose-container__text.two-line {
          display: none;
        }
      }
      .goods-color__new {
        right: -0.08rem;
      }
      .goods-color__soldout {
        border: none;
        i {
          width: 3.8rem;
          height: 2px !important;
          transform: translate3d(0, 0, 0) rotate(-44.8deg) scale(0.5);
          &::before, &::after{
            border: 0.5px solid hsla(0,0%,100%,.6);
          }
        }
      }
      .skc-prepose-container img {
        object-fit: cover;
      }
    }
    .goods-color__prepose-color-c {
      margin-right: 8px;
      .goods-color__soldout {
        border-radius: 50%;
        background: var(---sui_color_white_alpha60, rgba(255, 255, 255, 0.60));
        i {
          width: 3.8rem;
          height: 2px !important;
          transform: translate3d(0, 0, 0) rotate(-48.8deg) scale(0.5);
          &::before, &::after{
            border: 0.5px solid hsla(0,0%,100%,.6);
          }
        }
      }
      .goods-color__new {
        .right(-0.0987rem);
      }
      
      border-radius: 50%;
      .skc-prepose-container__img {
        border-radius: 50%;
        width: 0.6933rem;
        height: 0.6933rem;
        border: 0.5px solid rgba(102, 102, 102, 0.30);
      }
      &.color-active {
        padding: 0.0533rem;
        .skc-prepose-container__img {
          margin-right: 0;
          border-radius: 50%;
          width: 0.6933rem;
          height: 0.6933rem;
        }
        .skc-prepose-container__text.two-line {
          display: none;
        }
        .skc-prepose-container__text {
          display: none;
        }
      }
    }
  }
  
  &__list {
    margin-top: 0.15rem;
    margin-bottom: 0.0533rem;
    font-size: 0;
    .flexbox();
    flex-flow: row wrap;
  }
  &__line {
    flex-flow: row nowrap;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__item {
    .margin-r(0.32rem);
    margin-bottom: 0.32rem;
    width: 26px;
    height: 26px;
    padding: 2px;

    &.active {
      width: 28px;
      height: 28px;
      position: relative;
      padding: 3px;
      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid #222222;
        border-radius: 100%;
      }
    }

    &-inner {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      box-sizing: border-box;
      border: 1px solid rgba(34, 34, 34, 0.06);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;

      &.soldout {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(0, -50%, 0) rotate(-45deg);
          transform-origin: center;
          width: 100%;
          height: 2px;
          background: rgba(153, 153, 153, 0.8);
          z-index: 1;
        }
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(0, -50%, 0) rotate(-45deg);
          transform-origin: center;
          width: 100%;
          height: 4px;
          background: rgba(255, 255, 255, 0.9);
          font-size: 0;
        }
      }
    }
  }

  &__tag {
    position: absolute;
    top: -0.02rem
  }

  &__low {
    .right(-.1867rem);
    width: .48rem;
    height: .27rem;
  }
  &__hot {
    .right(-0.32rem);
    width: 0.6667rem;
    height: 0.2667rem;
  }
  &__new {
    .right(-0.4266rem);
    width: 0.72rem;
    height: 0.2667rem;
  }
  &__evolu {
    .right(-0.16rem);
    width: 0.4533rem;
    height: 0.2667rem;
  }
  &__thickening {
    .right(-0.25rem);
    width: 0.56rem;
    height: 0.2667rem;
  }

  &__preColor {
    top: -0.026rem;
  }
  &__soldout {
    position: absolute;
    top: 0;
    left: 0 /*rtl:ignore*/;
    right: 0 /*rtl:ignore*/;
    bottom: 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.6);
    i {
      width: 1.84rem;
      height: 8px;
      position: absolute;
      transform-origin: left center;
      transform: translate3d(0%, 0, 0) rotate(-45deg) scale(0.5);
      background: rgba(153, 153, 153, 0.6);
      bottom: -4px;
      left: 0 /*rtl:ignore*/; // 不要用.left()
      [data-dpr='1'] & {
        height: 4px;
        bottom: -2px;
      }
      [data-dpr='3'] & {
        height: 12px;
        bottom: -6px;
      }
      &::before {
        position: absolute;
        top: -4px;
        content: '';
        width: 100%;
        display: block;
        border: 2px solid rgba(255, 255, 255, 0.6);
        [data-dpr='1'] & {
          top: -2px;
        }
        [data-dpr='3'] & {
          top: -6px;
        }
      }
      &::after {
        position: absolute;
        bottom: -4px;
        content: '';
        width: 100%;
        display: block;
        border: 2px solid rgba(255, 255, 255, 0.6);
        [data-dpr='1'] & {
          bottom: -2px;
        }
        [data-dpr='3'] & {
          bottom: -6px;
        }
      }
    }
  }
  &__tag-discount {
    position: absolute;
    top: 0;
    left: 100%;
    transform-origin: 0 0;
    transform: scale(0.5) translate(-50%, -50%);
    border: 0.042rem solid rgba(255, 255, 255, 0.86);
    border-radius: 0.1rem;
    padding: 0.1rem;
    overflow: hidden;
    font-size: 0.42rem;
    line-height: 1.25;
    background: @sui_color_promo_bg;
    color: @sui_color_promo_dark;
    white-space: nowrap;
  }
  &__tag-promotion {
    position: absolute;
    top: 0;
    left: 100%;
    transform-origin: 0 0;
    transform: translate(-50%, -50%);
    border: 0.021rem solid rgba(255, 255, 255, 0.86);
    border-radius: 0.053rem;
    padding: 0.026rem 0.026rem 0.026rem 0.053rem;
    overflow: hidden;
    font-size: 0.32rem;
    line-height: 1;
    background: @sui_color_promo_bg;
    color: @sui_color_promo_dark;
  }
  &__promotion-link {
    margin: -0.11rem 0 0.64rem;
    &_large {
      margin-top: 0.213rem;
    }
    &_desc {
      margin-bottom: 0.213rem;
    }
  }
  &__promotion-link-box {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    color: #c44a01;
    overflow: hidden;
  }
  &__promotion-link-text {
    margin: 0 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.color-active {
  .skc-prepose-container__text.two-line {
    .line-camp(2);
    white-space: normal;
    word-wrap: break-word;
  }
}
</style>
