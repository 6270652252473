<template>
  <BHeaderCart
    v-if="isNewHead"
    ref="refBHeaderCart"
    :themeVars="themeVars"
    :placement="placement"
    :offset-y="offsetY"
    :loc="loc"
    :style="commonHeaderColorVariable"
    @before-click="onBeforeClick"
  />
  <OldCart
    v-else
    :topTextColor="topTextColor"
    :placement="placement"
    :offset-y="offsetY"
    :loc="loc"
    :icon-size="iconSize"
    @click="onClick"
    @click-tip="onClickTip"
    @expose="onExpose"
    @expose-tip="onExposeTip"
  />
</template>

<script setup>
import { ref, computed, onMounted, defineAsyncComponent, getCurrentInstance } from 'vue'

import BHeaderCart from '@shein-aidc/bs-header-cart-mobile-vue2'
const OldCart = defineAsyncComponent(() => import('@/public/src/pages/components/common_header/cart/index.vue'))

import { web_cart_entry_click } from '@/public/src/pages/cartNew/utils/metricTagsConfig.js'
import { markPoint } from '@/public/src/services/mark/index.js'

import ControlAsync from '@/public/src/pages/components/common_header/utils/controlAsync.js'
const headerAnalysis = new ControlAsync({
  file: () => import(/* webpackChunkName: "header-analysis" */ '@/public/src/pages/components/common_header/analysis/index.js')
}).run()

const useRouter = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$router
}
const useStore = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$store
}

const router = useRouter()
const store = useStore()

const props = defineProps({
  themeVars: {
    type: Object,
    default: () => ({}),
  },
  placement: {
    type: String,
    default: 'bottom-end', // top-start
  },
  offsetY: {
    type: Number,
    default: undefined,
  },
  loc: {
    type: String,
    default: 'buttonbar',
  },
})

const isNewHead = computed(() => store.state.IS_NEW_HEAD)

const topTextColor = computed(() => {
  return props.themeVars.iconColor
})
const iconSize = computed(() => {
  return (props.themeVars.iconSize || 24) + 'px'
})
const commonHeaderColorVariable = computed(() => {
  return {
    '--shein-common-header-icon-color': props.themeVars.iconColor,
    '--shein-common-header-badge-border-color': props.themeVars.iconColor ? '#fff' : ''
  }
})

const onClick = ({ extraData }) => {
  markPoint('toNextPageClick', 'public')
  headerAnalysis().then(res => res.clickBag(extraData)).finally(() => {
    router.push(`${gbCommonInfo.langPath}/cart`)
  })
}
const onClickTip = ({ extraData }) => {
  headerAnalysis().then(res => res.clickBagTip(extraData)).finally(() => {
    router.push(`${gbCommonInfo.langPath}/cart`)
  })
}
const onExpose = ({ extraData }) => {
  headerAnalysis().then(res => res.exposeBag(extraData))
}
const onExposeTip = ({ extraData }) => {
  headerAnalysis().then(res => res.exposeBagTip(extraData))
}

const onBeforeClick = () => {
  web_cart_entry_click('3')
  markPoint('toNextPageClick', 'public')
}

const refBHeaderCart = ref(null)
onMounted(() => {
  refBHeaderCart.value?.onExpose()
})
</script>
