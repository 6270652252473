<script lang="jsx">
/**
 * @component 加车栏下的店铺按钮
 */
import { Icon } from '@shein-aidc/icon-vue2'
import moreMixin from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/DetailSignBoard/mixins/more.js'
import { mapGetters, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default {
  name: 'StoreBtn',
  components: {
    Icon,
  },
  mixins: [moreMixin],
  computed: {
    ...mapGetters('productDetail/common', ['fs_abt', 'base_info']),
    ...mapGetters('productDetail/DetailSignBoard', ['store_detail']),
    isShowNewFollowIcon(){
      return this.fs_abt?.storeiconchange?.param?.storeiconchange === 'new'
    },
    exposeConfig() {
      const { name, store_code, storeBusinessType } = this.store_detail
      return {
        itemDAEventExpose: '1-6-1-186',
        itemDAEventClick: '1-6-1-187',
        sa: {
          src_module: 'DetailStoreIcon',
          src_identifier: [
            `on=store`,
            `cn=${name || ''}`,
            `hz=0`,
            `ps=1`,
            `jc=thirdPartyStoreHome_${store_code || ''}`
          ].join('`'),
          store_tp: storeBusinessType === 2 ? 'brand' : 'other',
          store_code: store_code || '',
          button_label: this.showNewArrival ? 'icon_new' : '-'
        }
      }
        
    }

  },
  methods: {
    ...mapActions('productDetail/common', ['detail_to_store_data']),
    viewStore() {
      const { type, id, link } = this.store_detail
      const { base_info, exposeConfig } = this
      daEventCenter.triggerNotice({
        daId: exposeConfig.itemDAEventClick,
        extraData: { ...exposeConfig.sa }
      })
      // const storageKey = `storenew_hide_${store_code}`
      // let val = getLocalStorage(storageKey)
      // if(!val && showNewArrival){
      //   // 上新标签缓存3天
      //   setLocalStorage({ key: storageKey, value: true, expire: 1000 * 60 * 60 * 24 * 3 }) 
      // }
      let sort = ''
      let tabId = ''
      let adp = base_info.goods_id
      let isStoreTabSticky = ''
      // if(showNewArrival) {
      //   sort = '9'
      //   tabId = 'items'
      //   adp = ''
      //   isStoreTabSticky = '1'
      // } else { // 店铺上新时原跳转逻辑不变
      this.detail_to_store_data()

      this.boardMoreHandle({
        isStoreTabSticky,
        sort,
        tabId,
        type,
        id,
        link,
        goods_id: base_info.goods_id,
        cat_id: base_info.cat_id,
        adp,
        analysis: { src_identifier: exposeConfig.sa.src_identifier, src_module: exposeConfig.sa.src_module }
      })
    },
  },
  render() {
    const {
      exposeConfig,
      viewStore,
      isShowNewFollowIcon
    } = this
    return (
      <div 
        class='add-cart__store-btn'
        v-expose={{
          id: exposeConfig.itemDAEventExpose,
          data: {
            ...exposeConfig.sa,
          }
        }}
        onClick={viewStore}>
        <Icon name={isShowNewFollowIcon ? 'sui_icon_store2_32px' : 'sui_icon_store_32px'} size="32px" />
      </div>
    )
  }
}
</script>
<style lang="less">
.add-cart__store-btn{
  width: 0.85333rem;
  height: 1.07rem;
  line-height: 1.07rem;
  flex-shrink: 0;
  text-align: center;
  margin-right: 0.32rem;
  position: relative;
}
.add-cart__store-btn-new{
  height: 0.37333rem;
  line-height: 0.37333rem;
  padding: 0 0.05333rem;
  position: absolute;
  left: 0.34666rem;
  top: -0.02666rem;
  border-radius: 0.08rem 0.08rem 0.08rem 0;
  border: 1px solid #FFF;
  background: #3CBD45;
  font-size: 0.26667rem;
  color: #fff;
  z-index: 1;
}
</style>
