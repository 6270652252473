<script lang="jsx">
import ClientOnly from 'vue-client-only'
import { transformImg } from '@shein/common-function'
import ColorV2AddTag from './ColorV2AddTag.vue'
import CropImageContainerJsx from 'public/src/pages/components/CropImageContainer/CropImageContainerJsx.vue'
import CropImageContainerPreposeJsx from './CropImageContainerPreposeJsx.vue'

export default {
  name: 'ColorV2Radio',
  functional: true,
  components: {
    ClientOnly,
    ColorV2AddTag,
  },
  props: {
    colorType: {
      type: String,
      default: 'default',
    },
    loadConf: {
      type: Object,
      default: () => ({
        size: '750x',
        num: 1
      }),
    },
    colorsInfo: {
      type: Array,
      default: () => [],
    },
    mainGoodsId: {
      type: String,
      default: '',
    },
    eventCategory: {
      type: String,
      default: '',
    },
    reviewLocation: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: '',
    },
    colorConf: {
      type: Object,
      default: () => ({}),
    },
    promotionTagInfos: {
      type: Object,
      default: () => ({}),
    },
    switchColor: {
      type: Function,
      default: () => {},
    },
    preposeColorType: {
      type: String,
      default: 'Thumbnail'
    },
    skcPreposeVal: {
      type: String,
      default: ''
    },
    labelExpose: {
      type: Function,
      default: () => {}
    },
    fixedRatio: {
      type: String,
      default: ''
    },
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    /**
     * 加载状态
     */
    loading: {
      type: Boolean,
    },
    /**
     * 最后点击的色块
     */
    lastClickColor: {
      type: String,
      default: ''
    },
    supportLargeImageList: {
      type: Boolean,
      default: false
    },
    analysisInfo: {
      type: Object,
      default: () => ({})
    },
    isSkcPrepose: {
      type: Boolean,
      default: false
    },
    lazyImg: {
      type: String,
      default: ''
    },
    isShowAttrNewIcon: {
      type: Boolean,
      default: false
    },
    showColorsTag: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => ({})
    },
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  render(h, { props, injections }) {
    const { cutImg } = injections || {}

    const { 
      colorType,
      loadConf,
      colorsInfo,
      mainGoodsId,
      eventCategory,
      reviewLocation,
      from,
      colorConf,
      promotionTagInfos,
      switchColor,
      labelExpose,
      fixedRatio,
      isSupportCropImage,
      preposeColorType,
      skcPreposeVal,
      loading,
      lastClickColor,
      supportLargeImageList,
      analysisInfo,
      isSkcPrepose,
      lazyImg,
      isShowAttrNewIcon,
      showColorsTag,
    } = props
    const { PUBLIC_CDN = '' } = gbCommonInfo || {}

    // 如果存在加载状态，显示最后点击的颜色
    const _useLastClickColor = loading && lastClickColor
    const currentGoodsId = _useLastClickColor ? +lastClickColor : mainGoodsId || ''


    const baseCutImg = (imgUrl, imgDesignWidth = 0, imgDataExp = {}) => {
      if (!cutImg) {
        return transformImg({ img: imgUrl })
      }
      return cutImg(imgUrl, imgDesignWidth, imgDataExp)
    }

    const renderColorItem = () => {
      return colorsInfo.map((item, key) => {
        let icon_type = ''
        if (item.isEco == '1') {
          icon_type = 'evo'
        } else if (item.isNew == '1' && isShowAttrNewIcon) {
          icon_type = 'new'
        } else if(item.lowPrice) {
          icon_type = 'low_price'
        } else if (item.hot_color == '1') {
          icon_type = 'hot'
        } else if (item.isShowThickening == '1') {
          icon_type = 'thickening'
        }
        // let icon_type = 
        // item.isEco == '1' ? 'evo' :
        //   item.isNew == '1' && isShowAttrNewIcon ? 'new' :
        //     item.hot_color == '1' ? 'hot' : ''
        const { goods_id, attr_value, isSoldOutStatus, goods_sn } = item
        return (
          <li 
            key={goods_id} 
            {...{ directives: [{ name: 'ada', value: { level: 1, pos: key } }] }}
            role="text"
            aaa="v2"
            aria-label={attr_value}
            class={[{
              'goods-color__item': colorType == 'default',
              'goods-color__img-item': colorType == 'img',
              'goods-color__text-item': colorType == 'text',
              'goods-color__prepose-item': colorType == 'prepose',
              'goods-color__prepose-img-c': skcPreposeVal == 'C' && preposeColorType === 'Thumbnail',
              'goods-color__prepose-color-c': skcPreposeVal == 'C' && preposeColorType !== 'Thumbnail',
              'size-sold-out': colorType == 'text' && isSoldOutStatus && mainGoodsId == item.goods_id,
              'sold-out': colorType == 'text' && isSoldOutStatus,
              'color-active': goods_id == currentGoodsId,
            }]}
            data-sku={goods_sn}
            data-goodsid={goods_id}
            data-soldout={Number(!!isSoldOutStatus)}
            data-event-category={eventCategory}
            data-review-location={reviewLocation}
            data-is_front={isSkcPrepose ? 1 : 0}
            data-location={from}
            data-source={colorConf && colorConf.analysisConfig && colorConf.analysisConfig.source}
            data-promotion-type={promotionTagInfos && promotionTagInfos[goods_id] && promotionTagInfos[goods_id].typeId || '-'}
            data-image-tp="small"
            data-is-attr-fold={analysisInfo.isAttrFold}
            data-position={key + 1}
            data-icon_type={icon_type}
            da-event-click="1-6-1-32"
            vOn:click={() => {
              switchColor(item)
            }}
          >
            {colorType === 'prepose' ? renderImgColorsPrepose(item, key) : renderColorItemContent(item, key)}
            <ClientOnly>
              <ColorV2AddTag
                item={item}
                colorType={colorType}
                preposeColorType={preposeColorType}
                isLargeImage={false}
                promotionTagInfos={promotionTagInfos}
                labelExpose={labelExpose}
                publicCdn={PUBLIC_CDN}
                isShowAttrNewIcon={isShowAttrNewIcon}
                showColorsTag={showColorsTag}
              />
            </ClientOnly>
          </li>
        )
      })
    }

    const renderColorItemContent = (item, key) => {
      let content = null
      if (colorType === 'default') {
        content = renderDefaultColors(item, key)
      } else if (colorType === 'img') {
        content = renderImgColors(item, key)
      } else {
        content = renderTxtColors(item, key)
      }

      return (
        <a
          class={[{ 'fsp-element': key === 0 }]}
          fsp-key={`${item?.goods_id}_${item?.attr_value}`}  
          href="javascript:;"
          aria-hidden="true"
        >
          {content}
          {renderColorSoldOut(item)}
        </a>
      )
    }

    /** 颜色色块(圆形)[默认] */
    const renderDefaultColors = (item) => {
      const { goods_id, goods_color_image, isSoldOutStatus } = item
      return (
        <div 
          class={{
            'goods-color__item': true,
            'active': goods_id == currentGoodsId
          }}
          key={goods_id}
        >
          <div 
            style={{
              backgroundImage: `url(${baseCutImg(goods_color_image, 28, { max: 48 })})`
            }} 
            class={{
              'goods-color__item-inner': true,
              'soldout': isSoldOutStatus
            }}
          >
          </div>
        </div>
      )
    }

    /** 图片色块(方形) */
    
    const renderImgColorsPrepose = (item, key) => {
      const { isSoldOutStatus, attr_value, isShowThickening } = item
      const imgType = preposeColorType === 'Thumbnail' ? 'goods_image' : 'goods_color_image'
      const attrValue = `${attr_value}${isShowThickening == '1' ? ' ' + props.language?.SHEIN_KEY_PWA_34950 : ''}`

      // 前 7 个方形色块使用src直出
      let content = null
      if (key <= 6) {
        content = <CropImageContainerPreposeJsx
          fixedRatio={fixedRatio}
          isSupportCropImage={isSupportCropImage}
          imgSrc={baseCutImg(item[imgType], 80, { max: 100 })}
          colorBlockText={attrValue}
          saleOut={!!isSoldOutStatus}
        />
      }else{
        // 后续的使用懒加载
        content = <CropImageContainerPreposeJsx
          fixedRatio={fixedRatio}
          isSupportCropImage={isSupportCropImage}
          imgSrc={transformImg({ img: item[imgType] })}
          colorBlockText={attrValue}
          saleOut={!!isSoldOutStatus}
          imgProps={{
            src: lazyImg,
            dataDesignWidth: '80',
            class: 'lazyload',
            dataExp: 'max=100'
          }}
        />
      }

      return (
        <a
          class={[{ 'fsp-element': key === 0 }]}
          href="javascript:;"
          aria-hidden="true"
          fsp-key={`${item?.goods_id}_${item?.attr_value}`}  
        >
          {content}
          {renderColorSoldOut(item)}
        </a>
      )

    }

    /** 图片色块(方形) */
    const renderImgColors = (item, key) => {
      // 前 7 个方形色块使用src直出
      if (key <= 6) {
        return (
          <CropImageContainerJsx
            fixedRatio={fixedRatio}
            isSupportCropImage={isSupportCropImage}
            imgSrc={baseCutImg(item['goods_image'], 80, { max: 100 })}
          />
        )
      }

      // 后续的使用懒加载
      return (
        <CropImageContainerJsx
          fixedRatio={fixedRatio}
          isSupportCropImage={isSupportCropImage}
          imgSrc={transformImg({ img: item['goods_image'] })}
          imgProps={{
            src: lazyImg,
            dataDesignWidth: '80',
            class: 'lazyload',
            dataExp: 'max=100'
          }}
        />
      )
    }

    /** 文字色块 */
    const renderTxtColors = (item) => {
      const { attr_value } = item
      if (!attr_value) {
        return null
      }
      return attr_value
    }

    const renderColorSoldOut = (item) => {
      if (item.isSoldOutStatus && colorType != 'default' && colorType != 'text') {
        return (
          <div
            class="goods-color__soldout"
          >
            <i></i>
          </div>
        )
      }
      return null
    }

    return (
      <ul
        class={[{
          'goods-color__imgs': colorType == 'img',
          'goods-color__list': colorType == 'default',
          'goods-color__text': colorType == 'text',
          'goods-color__prepose': colorType == 'prepose',
          'no-right-arrow': !supportLargeImageList,
          'goods-color__wrap': true,
          'goods-color__line': loadConf.isLine
        }]}
      >
        {renderColorItem()}
      </ul>
    )
  }
}

</script>
