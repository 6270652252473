var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{directives:[{name:"ada",rawName:"v-ada",value:({ level: 0, pos: 0 }),expression:"{ level: 0, pos: 0 }"}],ref:"addBtnRef",staticClass:"productAddBtn j-da-event-box j-add-to-bag add-cart__normal-btn",style:(_setup.btnStyle),attrs:{"data-poskey":"QuickShow"},on:{"click":_setup.handleClick}},[(_setup.addTextInfo.positionTop)?_c('p',{staticClass:"productAddBtn__addText",style:(_setup.addTextInfo.style)},[_vm._v("\n    "+_vm._s(_setup.addTextInfo.value)+"\n  ")]):_vm._e(),_vm._v(" "),(_setup.realTimeReady)?_c('div',{ref:"contentRef",staticClass:"productAddBtn__content",style:({
      ..._setup.contentStyle,
      ..._setup.contentMargin
    })},[(_setup.priceInfo)?_c('p',{staticClass:"productAddBtn__price",style:({
        color: _setup.priceInfo.style.color,
        fontSize: _setup.priceInfo.style.fontSize
      })},[(_setup.priceInfo.beforeText)?_c('span',{staticClass:"productAddBtn__price-before"},[_vm._v("\n        "+_vm._s(_setup.priceInfo.beforeText)+"\n      ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"productAddBtn__price-price",style:({
          fontWeight: _setup.priceInfo.style.fontWeight
        })},[_vm._v("\n        "+_vm._s(_setup.priceInfo.value)+"\n      ")])]):_vm._e(),_vm._v(" "),(_setup.retailPriceinfo)?_c('p',{directives:[{name:"show",rawName:"v-show",value:(_setup.showRetailPrice),expression:"showRetailPrice"}],staticClass:"productAddBtn__retailPrice"},[_vm._v("\n      "+_vm._s(_setup.retailPriceinfo)+"\n    ")]):_vm._e(),_vm._v(" "),(_setup.benefitInfo)?_c('div',{staticClass:"productAddBtn__benefit",style:(_setup.benefitInfo.style)},[(_setup.showBenefitImg)?_c('img',{staticClass:"productAddBtn__benefit-img",attrs:{"src":_setup.benefitInfo.imgSrc}}):_vm._e(),_vm._v(" "),_c('p',{staticClass:"productAddBtn__benefit-text"},[_vm._v("\n        "+_vm._s(_setup.benefitValue)+"\n      ")])]):_vm._e()]):_vm._e(),_vm._v(" "),(_setup.addTextInfo.positionBottom)?_c('p',{staticClass:"productAddBtn__addText",style:(_setup.addTextInfo.style)},[_vm._v("\n    "+_vm._s(_setup.addTextInfo.value)+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }