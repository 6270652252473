<script lang="jsx">
/**
 * @component 加车栏下的常规按钮盒子
 */

import { mapState, mapGetters, mapActions } from 'vuex'
import RetentionTips from 'public/src/pages/goods_detail/components/RetentionTips.vue'
import FreeShippingTips from 'public/src/pages/goods_detail/components/FreeShippingTips'
import BtnContentWithPrice from './BtnContentWithPrice'
import { CountDown, debounce } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { showBuyNow } from 'public/src/pages/product_app/store/modules/product_detail_new/utils/buyNow.js'
import { parsePercentText } from 'public/src/pages/goods_detail/utils/ar.js'
const { lang } = gbCommonInfo || {}

export default {
  name: 'NormalBtnBox',
  components: {
    RetentionTips,
    FreeShippingTips,
    BtnContentWithPrice,
  },
  props: {
    // 使用半边样式，目的是容纳一键购按钮
    // * 一键购在此组件不做任何判断，由父级经由slot传入
    halfWidthStyle: {
      type: Boolean,
      default: false,
    },
    // TODO-perf 应由vuex接管计算
    showPriceModuleAbt: {
      type: String,
      default: '',
    },
    // 展示按钮下方价格
    showPrice: {
      type: Boolean,
      default: false,
    },
    priceExposeAbt: {
      type: String,
      default: '',
    },
    // 价格区域使用折扣样式
    useDiscountStyle: {
      type: Boolean,
      default: false,
    },
    // 定制商品
    isCustomization: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      isShowCountdown: false, // 到手价倒计时
      showCountTime: false, // 闪购倒计时
      countdownObj: null,
      flashTimer: null,
      countTimer: {}
    }
  },
  computed: {
    ...mapState('productDetail/gift', ['gift_selected_list']),
    ...mapState('newProductDetail/common', ['buyNowBtnLoading', 'oneClickPayState', 'oneClickPayVerifyDoneStatus', 'realTimeReady', 'skuInfo']),
    ...mapGetters('newProductDetail', ['fsAbt', 'isPaidUser', 'exclusivePromotionPrice']), // 新数据流
    ...mapGetters('newProductDetail/Price', ['estimatedInfo', 'estimatedPriceEndTime']), // 新数据流
    ...mapGetters('newProductDetail/common', [
      'goodsId',
      'productPriceInfo',
      'promotionInfoDesc',
      'getEstimatedInfo',
      'language', 
      'isBuyNow',
      'isDetailBuyNowPriceCeiling',
      'isSoldOut',
      'tsp',
      'getEstimatedInfo',
      'parentCats',
      'complianceMode',
      'flashGoods',
      'isSpecialFlash',
      'detail',
      'mallCode',
      // 'saleAttrList',
      'cccAddCartPrompt',
      'complianceTipsMode',
      'retentionPositionConfig',
      'showFrom',
      'complianceModeDe',
      'sheinClubPromotionInfo',
      'isHitComplianceMode',
      'currentMall'
    ]), // 新数据流
    ...mapGetters('newProductDetail/ShippingEnter', [
      // hot
      'freeShippingInfo',
    ]),
    ...mapGetters('newProductDetail/Price', [
      'discountInfo',
      'salePrice',
      'isInversion',
      'isInversionDe'
    ]),
    ...mapGetters('newProductDetail/TopOther', ['showStoreBtn']),
    ...mapGetters('newProductDetail/Top', ['lowestPriceTipsConfig']),
    // ...mapState('newProductDetail/SizeBox', ['soldoutState']),
    ...mapGetters('newProductDetail/SizeBox', ['tipsCombo']),
    ...mapGetters('newProductDetail/PriceBelt', [
      'isFlashSaleCountDownAbtHide',
      'isBrandFlash',
      'showPriceBelt'
    ]),
    ...mapState('newProductDetail/PriceBelt', [
      'flashSaleCountDownTime'
    ]),

    // new
    ...mapGetters('productDetail/common', [
      'is_use_bff_fs',
      'sku_list',
      'mall_code'
    ]),
    isStrengthen(){
      return this.fsAbt?.Interestpointstyle?.p?.Interestpointstyle === 'obvious'
    },
    endTime() {
      return this.flashGoods?.endTimestamp || 0
    },
    // 默认按钮文案
    defaultBtnText() {
      if (this.isCustomization) {
        return this.language.SHEIN_KEY_PWA_26707
      }
      return this.language.SHEIN_KEY_PWA_15019
    },
    // 包邮-按钮角标
    showFreeShipping () {
      return this.freeShippingInfo?.freeShippingStyle?.isAddbag || false
    },
    // 保留款提示
    // showRetentionTips() {
    //   const { position = '', show } = this.retentionPositionConfig
    //   return show && position == 'button'
    // },
    // 按钮上报abt
    buttonAbt() {
      const { showPriceModuleAbt, showPrice } = this
      return showPriceModuleAbt && showPrice ? 'QuickShow' : ''
    },
    countdownTips () { 
      const { H, M, S } = this.countTimer?.timeObj || {}
      let spanTxt = txt => `<span>${txt}</span>`
      return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S)
    },
    flashCountdownTips () { 
      const { H, M, S } = this.countdownObj || {}
      let spanTxt = txt => `<span>${txt}</span>`
      if(!H && !M && !S) return ''
      return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S)
    },
    isBuyNowForWatch() {
      const { oneClickPayVerifyDoneStatus, realTimeReady, isBuyNow } = this
      return {
        oneClickPayVerifyDoneStatus,
        realTimeReady,
        isBuyNow
      }
    },
    discountPercent() {
      // 有from标签不展示折扣
      // if(this.showFrom) return ''
      // 有到手价优先到手价，否则取售价折扣
      const { getEstimatedInfo, hasEstimated } = this.estimatedInfo || {}
      if(hasEstimated && getEstimatedInfo.discountPercentForBelt) return getEstimatedInfo.discountPercentForBelt
      const {  value = '' } = this.discountInfo?.discountPercent || {}
      return value ? parsePercentText(value + '%') : ''
    },
    // skuList() {
    //   return this.saleAttrList?.skuList ?? []
    // },
    soldOutMaxNum() {
      return this.tipsCombo?.soldoutTipsLimitNum
    },
    //是否下架和售罄
    isAllSoldOut() {
      return (
        Number(this.detail.is_on_sale) === 0 || Number(this.detail.stock) === 0
      )
    },
    // 所有sku库存紧张
    almostSoldOut() {
      let skuStock = 0
      const { sku_list, soldOutMaxNum, mall_code } = this
      for (let i = 0; i < sku_list.length; i++) {
        const _mallStock = sku_list[i]?.mall_stock?.find(d => d.mall_code == mall_code)
        skuStock += +_mallStock?.stock || 0
        if (skuStock > soldOutMaxNum) {
          return false
        }
      }
      if (skuStock == 0) {
        return false
      }
      return true
    },
    soldOutTips() {
      const { stock, is_absolute_low_inventory, is_relatively_low_inventory } =  this.currentMall
      if(stock <= 0) {
        return ''
      }else if(is_absolute_low_inventory === true) {
        return this.changeTips(stock)
      }else if(is_relatively_low_inventory === true) {
        return this.language.SHEIN_KEY_PWA_16827 || 'Hurry! Almost Sold Out...'
      }
      return ''
    },
    showSoldOutTips() {
      return this.sizeStockTips
    },
    sizeStockTips() {
      const { stock, is_absolute_low_inventory, is_relatively_low_inventory } = this.currentMall
      let judge
      if(stock <= 0) {
        judge = false
      } else if (is_absolute_low_inventory || is_relatively_low_inventory) {
        judge = true
      } else {
        judge = !this.isAllSoldOut && this.almostSoldOut
      }
      // TODO
      return judge
    },
    soldoutState() {
      return {
        showSoldOutTips: this.showSoldOutTips,
        soldOutTips: this.soldOutTips
      }
    },
    bestDealConfig() {
      const { lowestPriceTipsConfig, retentionPositionConfig } = this
      return {
        sameitemprice: retentionPositionConfig,
        historyprice: lowestPriceTipsConfig,
      }
    }
  },
  watch: {
    estimatedInfo(){
      this.initTimer()
    },
    promotionInfoDesc(){
      this.initTimer()
    },
    // isBuyNow: {
    //   handler(val) {
    //     if (!val) return 
    //     this.$nextTick(() => {
    //       this.triggerExposeBuyNow()
    //     })
    //   },
    //   immediate: true
    // }
    // 延后上报满足条件的 abt 未命中的立即购曝光
    isBuyNowForWatch(newVal) {
      const { oneClickPayVerifyDoneStatus, realTimeReady, isBuyNow } = newVal
      if (oneClickPayVerifyDoneStatus && realTimeReady && !isBuyNow) {
        showBuyNow({
          goodsId: this.goodsId,
          DetailBuynowAbt: this.fsAbt?.DetailBuynow?.p,
          isSoldOut: this.isSoldOut,
          oneClickPayStateSupport: this.oneClickPayState.support,
          isCustomization: this.isCustomization,
          tsp: this.tsp,
          isFreeShippin: this.freeShippingInfo?.isFreeShippin,
          getEstimatedInfo: this.getEstimatedInfo,
          salePriceUsdAmount: this.salePrice.salePriceUsdAmount,
          lang: lang,
          parentCats: this.parentCats
        })
      }
    },
    endTime(newVal, oldVal) {
      if(newVal === oldVal) return
      this.showCountTime = false
      this.clearTimeAndInterval()
      this.initNormalFlashCountDown()    

    }
  },
  mounted() {
    this.initNormalFlashCountDown()
    // this.initTimer()
    setTimeout(() => {
      this.initTimer()
    }, 0)
  },
  beforeDestroy() {
    this.clearTimeAndInterval()
  },
  methods: {
    ...mapActions('newProductDetail', ['buyNow']),
    // 事件阻止冒泡
    stop(e) {
      e?.preventDefault?.()
      e?.stopPropagation?.()
    },
    // 加车按钮点击
    async handleAddCart(event) {
      this.$emit('addCart', event, this.benefitText)
    },
    changeTips(stock) {
      return this.template(stock, this.language.SHEIN_KEY_PWA_16814)
    },
    initTimer () { 
      const { estimatedPriceEndTime, isShowCountdown } = this.estimatedInfo?.estimatedCompProps?.config || {}
      this.isShowCountdown = isShowCountdown && !!this.getEstimatedInfo?.isSatisfied
      if(!isShowCountdown) return
      // 倒计时开始时间
      this.countTimer = new CountDown()
      const beginTime = estimatedPriceEndTime * 1000 - new Date().getTime()
      if (beginTime <= 0) {
        beginTime == 0
      }

      // 初始化倒计时
      this.countTimer?.start({
        seconds: beginTime / 1000,
        endFunc: debounce({
          func: () => {
            location.reload()
          }
        })
      })
    },
    // 渲染提示角标
    renderTips() {
      const {
        language,
        showFreeShipping,
        halfWidthStyle,
      } = this

      // 优先展示包邮-按钮角标
      if (showFreeShipping) {
        return (
          <div
            class={{
              'belt-tips': true,
              'belt-half': halfWidthStyle,
            }}
            onClick={stop}
          >
            <FreeShippingTips
              language={language}
            />
          </div>
        )
      }

      // 按钮内的悬浮保留款提示
      // if (showRetentionTips) {
      //   return (
      //     <div
      //       class={{
      //         'belt-tips': true,
      //         'belt-half': halfWidthStyle,
      //       }}
      //       onClick={stop}
      //     >
      //       <RetentionTips
      //         language={language}
      //       />
      //     </div>
      //   )
      // }

      return null
    },
    renderBuyNowBtn() {
      if (!this.isBuyNow) return null
      return <button 
        v-expose={{ id: '1-6-1-190', data: { 
          activity_from: 'main',
          goods_id: this.goodsId,
          location: 'page',
          is_buynow: '1',
          nobuynow_reason: '' } }}
        ref="buyNowBtnRef"
        class="buyNowBtn"
        onClick={this.clickBuyNow}
      >
        { this.buyNowBtnLoading ? <s-loading show={true} /> : 
          <s-adapter-text
            min-size="10"
            text={this.language.SHEIN_KEY_PWA_30145}
          />
        }
      </button>
    },
    clickBuyNow() {
      if (this.buyNowBtnLoading) return
      this.buyNow()
    },
    // triggerExposeBuyNow() {
    //   setTimeout(() => {
    //     daEventCenter.triggerNotice({
    //       daId: '1-6-1-190',
    //       extraData: {
    //         activity_from: 'main',
    //         goods_id: this.goodsId,
    //         location: 'page',
    //         is_buynow: '1',
    //         nobuynow_reason: '',
    //       }
    //     })
    //   }, 300)
    // }
    clearTimeAndInterval () {
      // 清除定时器
      this.flashTimer && this.flashTimer.clear()
      this.secondInterval && clearInterval(this.secondInterval)
    },
    //闪购倒计时
    async initNormalFlashCountDown () {
      if (this.isFlashSaleCountDownAbtHide) return
      if (this.isSpecialFlash) return
      this.clearTimeAndInterval()
      const data = await schttp({
        url: '/api/common/servicesTime/get'
      })
      let serverTime = data.zzz
      const currentTime = Date.now() / 1000 >> 0 // 获取当前时间
      if (!serverTime) {
        serverTime = currentTime
      }
      if (!this.endTime || this.endTime < serverTime ) {
        this.showFlashBelt = false
        return 
      }
      this.flashTimer = new CountDown({
        countFunc: (timeObj) => {
          const { D } = timeObj || {}
          // 倒计时小于72小时 = 3 天 时展示倒计时文案，品牌闪购3天
          let minDay = this.isBrandFlash ? 1 : 3
          if (Number(D) < minDay && this.showCountTime === false) {
            this.showCountTime = true
            // 额外百毫秒展示
            // this.secondInterval = setInterval(() => {
            //   this.secondHundred --
            //   if (this.secondHundred < 0) this.secondHundred = 9
            // }, 100)
          }
          if (this.showCountTime) {
            this.countdownObj = timeObj
          }
          
        },
        endFunc: () => {
          this.$store.dispatch('newProductDetail/asyncHotModles', {
            goods_id: this.goodsId,
            mallCode: this.mallCode
          })
          this.clearTimeAndInterval()
        }
      })
      this.flashTimer.start({
        dayMode: true,
        seconds: this.endTime - serverTime, // 单位是秒
      })
    },
    getBenefitText(text) {
      this.benefitText = text
    },
  },

  render() {
    const {
      language,
      showPrice,
      renderTips,
      halfWidthStyle,
      buttonAbt,
      showPriceModuleAbt,
      handleAddCart,
      useDiscountStyle,
      productPriceInfo,
      priceExposeAbt,
      getEstimatedInfo,
      fsAbt,
      countdownTips,
      isShowCountdown,
      defaultBtnText,
      isCustomization,
      renderBuyNowBtn,
      isDetailBuyNowPriceCeiling,
      isInversion,
      isInversionDe,
      complianceMode,
      complianceModeDe,
      complianceTipsMode,
      bestDealConfig,
      soldoutState,
      showCountTime,
      flashCountdownTips,
      discountPercent,
      cccAddCartPrompt,
      getBenefitText,
      isBuyNow,
      showStoreBtn,
      isStrengthen,
      isBrandFlash,
      showPriceBelt,
      isPaidUser,
      sheinClubPromotionInfo,
      exclusivePromotionPrice,
      isHitComplianceMode,
      gift_selected_list: giftSelectedList,
    } = this
    let btnWidth = 'calc(100% - 1.066667rem)'
    if (showStoreBtn) btnWidth = 'calc(100% - 2.3784rem)'
    if (halfWidthStyle) btnWidth =  'calc(100% + 0.16rem)'

    { /* 双按钮不展示利益点文案 */ }
    const showBenefitText = (this.$slots?.rightSideContent || isBuyNow) ? false : true
    return (
      <div
        {...(isCustomization ? {
          directives: [
            { name: 'tap', value: { id: '1-6-1-168', data: { activity_from: 'main' } } }
          ]
        } : {})}
        class={{
          'add-cart__normal-btn-box': true,
          'half-width-btn': halfWidthStyle,
        }}
        style={{ width: btnWidth, 'max-width': btnWidth }}
      >
        {/* 提示角标 */}
        {renderTips()}

        {renderBuyNowBtn()}

        <button
          ref="refAddBtn"
          {...{ directives: [{ name: 'ada', value: { level: 0, pos: 0 } }] }}
          data-poskey={buttonAbt}
          class={{
            'add-cart__normal-btn j-add-to-bag': true,
            ' j-da-event-box': showPriceModuleAbt,
            'mshe-btn-black': !halfWidthStyle,
            'mshe-btn-sec': halfWidthStyle
          }}
          onClick={handleAddCart}
        >
          {/* 展示混入价格文本/纯文本 */}
          
          {showPriceModuleAbt ? 
            <BtnContentWithPrice
              defaultBtnText={defaultBtnText}
              exclusivePromotionPrice={exclusivePromotionPrice}
              showBenefitText={showBenefitText}
              showPrice={showPrice}
              priceInfo={productPriceInfo}
              getEstimatedInfo={getEstimatedInfo}
              fsAbt={fsAbt}
              exposeAbt={priceExposeAbt}
              showType={showPriceModuleAbt}
              useDiscountStyle={useDiscountStyle}
              language={language}
              countdownTips={countdownTips}
              isShowCountdown={isShowCountdown}
              isInversion={isInversion || isInversionDe}
              complianceMode={complianceMode}
              complianceModeDe={complianceModeDe}
              complianceTipsMode={complianceTipsMode}
              bestDealConfig={bestDealConfig}
              soldoutState={soldoutState}
              isShowflashCountdown={showCountTime}
              flashCountdownTips={flashCountdownTips}
              discountPercent={discountPercent}
              isDetailBuyNowPriceCeiling={isDetailBuyNowPriceCeiling}
              cccAddCartPrompt={cccAddCartPrompt}
              onBenefitText={getBenefitText}
              isStrengthen={isStrengthen}
              isBrandFlash={isBrandFlash}
              showPriceBelt={showPriceBelt}
              isPaidUser={isPaidUser}
              sheinClubPromotionInfo={sheinClubPromotionInfo}
              isHitComplianceMode={isHitComplianceMode}
              giftSelectedList={giftSelectedList}
            /> : <p class="add-cart__normal-btn_text">{defaultBtnText}</p>
          }
        </button>

        {/* 一键购按钮插槽 */}
        {this.$slots?.rightSideContent || null}
      </div>
    )
  }
}
</script>

<style lang="less">
.add-cart__normal-btn-box {
  position: relative;
  .flexbox();
  .space-between();

  // 半宽按钮
  &.half-width-btn {
    .mshe-btn-black,
    .mshe-btn-sec {
      font-size: 0.373333rem;
      line-height: 1.066667rem;
      width: calc(50% - 0.16rem);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: none;
    }
  }

  // 保留款
  .belt-tips {
    position: absolute;
    top: -12/75rem;
    right: -12/75rem;
    z-index: @zindex-attrmenusize;
  }
  .belt-half {
    max-width: 50%;
  }

  .add-cart__normal-btn {
    height: 1.066667rem;
    line-height: 1.066667rem;
    overflow: hidden;
    /* rw:begin */
    font-family: 'Adieu';
    &.mshe-btn-black {
      width: 8.16rem;
      height: 1.07rem;
      line-height: 1.07rem;
      margin-top: 0;
    }

    &_text {
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
    }
  }

  .buyNowBtn {
    height: 1.0667rem;
    width: 3.0667rem;
    margin-right: .32rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    background: #FFF;
    color: #000;

    font-size: 16px;
    font-weight: 700;

    padding: 0 0.4rem;
    .S-loading__common-inner {
      margin: 0 !important;
    }
    & > p {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>

