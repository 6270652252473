<script lang="jsx">
import { template, transformImg } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
const { GB_cssRight } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
const DEFAULT_IMG_POSITION = {
  left: '.4rem',
  top: '9.3333rem'
}
daEventCenter.addSubscriber({ modulecode: '1-6-1' })

const ANIMATION_DEFAULT_BOX = '.add-cart__animation-normal'
const ANIMATION_REVIEW_BOX = '.add-cart__animation-review'


// 需实现三套动画
/**
 * 1. 购物车icon在header，从下往上缩（最开始的动画）
 * 2. 购物车icon在底部，从上往下飞入 collectandaddcart 命中A adddynamic 命中A
 * 3. 购物车icon在底部，红点动画，从加车按钮飞入购物车collectandaddcart 命中A adddynamic 不为A
 */
export default {
  name: 'AddPopAnimation',
  props: {
    originImage: {
      type: String,
      default: ''
    },
    isReview: {
      type: Boolean,
      default: false,
    },
    showFloatCartIcon: {
      type: Boolean,
      default: false
    },
    showTopCartIcon: {
      type: Boolean,
      default: true
    },
    currentMall: {
      type: Object,
      default: () => {}
    },
    showLowStockAddCartTips: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => {}
    },
    cartConfig: {
      type: Object,
      default: (() => {})
    }
  },
  data: () => {
    return {
      GB_cssRight,
      imagePosition: {
        left: DEFAULT_IMG_POSITION.left,
        top: DEFAULT_IMG_POSITION.top,
        minHeight: '10rem',
      }, 
      reddotPosition: {
        left: '0',
        top: '0',
      }
    }
  },
  computed: {
    animationBoxClass() {
      return this.isReview ? ANIMATION_REVIEW_BOX : ANIMATION_DEFAULT_BOX
    },
    isCartBottom () {
      return this.cartConfig.cartPosition === 'bottom'
    },
    cartDynamicRedDotStyle () {
      return this.isCartBottom && this.cartConfig.cartDynamicStyle === 'reddot'
    },
    cartBottomImgStyle() {
      return this.isCartBottom && this.cartConfig.cartDynamicStyle === 'img'
    }
  },
  mounted() {
    window.vBus && window.vBus.$on('triggerAddCompletedAnimation', this.triggerAddCompletedAnimation)
  },
  beforeDestroy() {
    window.vBus && window.vBus.$off('triggerAddCompletedAnimation', this.triggerAddCompletedAnimation)
  },
  methods: {
    triggerAddCompletedAnimation () {
      const { stock, sku_large_ship, is_absolute_low_inventory, is_relatively_low_inventory } = this.currentMall || {}
      if(this.showFloatCartIcon) {
        window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: true })
      } else if(this.showTopCartIcon) {
        this.cartDynamicRedDotStyle ? this.handleRedDotAnimation() : 
          this.cartBottomImgStyle ? this.handleImgAnimation() :
            this.handleTriggerAddCompletedAnimationTop() 
      } else {
        if(!this.showLowStockAddCartTips || (!is_absolute_low_inventory && !is_relatively_low_inventory)) {
          this.$emit('sucTip')
        }
      }
      if(this.showLowStockAddCartTips && (is_absolute_low_inventory || is_relatively_low_inventory)){
        let tips = ''
        if(is_absolute_low_inventory === true) {
          tips = template(stock, this.language.SHEIN_KEY_PWA_33088).trim()
        }else if(is_relatively_low_inventory === true) {
          tips = this.language.SHEIN_KEY_PWA_33087
        }
        this.$toast({
          htmlString: `<div class="low-stock-tips__addCart">
            <div class="success-tips-content">
              <i class='tips-icon sh_m_sui_icon_hourglass_12px_2'></i>
              ${tips}
            </div>
            <div>${this.language.SHEIN_KEY_PWA_33089}</div>
            </div>`,
          duration: 3000
        })
        // 进行低库存提示的曝光上报
        daEventCenter.triggerNotice({
          daId: '1-6-1-235',
          bindData: [{
            goods_level: sku_large_ship !== undefined ? 'sku' : 'skc',
            label_type: is_absolute_low_inventory ? 1 : 2,
            location: 'addtocarttoast',
          }]
        })
      }
    },
    // 购物车icon在底部，从上往下飞入 collectandaddcart 命中A adddynamic 命中A
    handleImgAnimation() {
      const image = this.$refs?.imgAnimate
      const addBtnDom = document.querySelector(this.animationBoxClass + ' .j-add-to-bag')
      const cartDom = document.querySelector(this.animationBoxClass + ' .j-add-cart__cart-btn')
      if(!image || !addBtnDom || !cartDom) return
      const addBtnRect = addBtnDom.getBoundingClientRect()
      const cartRect = cartDom.getBoundingClientRect()
      const startX = Math.floor(addBtnRect.left + (this.GB_cssRight ? 0 : addBtnRect.width / 2))
      const startY = addBtnRect.y - 86
      const endX =  cartRect.left - 43// 横向移动500px
      const peakY = -150 // 顶点（相对起始位置的垂直偏移，负值表示向上移动）
      const duration = 1000 // 动画时长，毫秒
      const startTime = performance.now()
      image.style.display = 'block'
      function parabola(t, peak) {
        return 4 * peak * t * (1 - t)
      }
      function animate(time) {
        const elapsedTime = time - startTime
        let t = elapsedTime / duration
        if (t > 1) t = 1
        // 水平位置（线性）
        const currentX = startX + (endX - startX) * t
        // 垂直位置（抛物线）
        const deltaY = parabola(t, peakY)
        const currentY = startY + deltaY
        // 缩放比例
        const scale = 1 - (0.85 * t)
        const opacity = 1 - (0.75 * t)
        image.style.left = currentX + 'px'
        image.style.top = currentY + 'px'
        image.style.opacity = opacity
        image.style.transform = `scale(${scale})`
        // 应用到图像位置和大小
        if (t < 1) {
          requestAnimationFrame(animate)
        } else {
          image.style.display = 'none'
        }
      }
      requestAnimationFrame(animate)
    },
    // 购物车icon在底部，红点动画，从加车按钮飞入购物车collectandaddcart 命中A adddynamic 不为A
    handleRedDotAnimation() {
      const addBtnDom = document.querySelector(this.animationBoxClass + ' .j-add-to-bag')
      const cartDom = document.querySelector(this.animationBoxClass + ' .j-add-cart__cart-btn')
      const ball = this.$refs?.flytocart
      if(!addBtnDom || !cartDom || !ball) return 
      const { y, width, left } = addBtnDom.getBoundingClientRect() || {}
      const cartRect = cartDom.getBoundingClientRect()
      // 设置初始位置j-add-to-bag
      const startTop = y - 16
      const startLeft = width / 2 + left
      // 计算动画结束位置
      const endLeft = cartRect.width / 2 + cartRect.left - 8
      const endTop = cartRect.y + 16
      ball?.classList?.remove?.('mshe-hide')
      this.reddotPosition.left = `${startLeft}px`
      this.reddotPosition.top = `${startTop}px`
      ball.style.display = 'block'
      // 起点和终点坐标
      const start = { 
        x: startLeft,
        y: startTop 
      }
      const end = { x: endLeft, y: endTop }
      // 动画持续时间，单位为毫秒
      const duration = 1000
      let startTime // 用来记录动画开始的时间
      function animate(timestamp) {
        if (!startTime) {
          // 初始化动画开始时间
          startTime = timestamp
        }
        // 计算动画已进行的时间
        const elapsedTime = timestamp - startTime
        // 计算已经过去的时间比例
        let t = (elapsedTime / duration)
        if (t > 1) t = 1 // 确保t不超过1
        // 计算小球当前的位置
        // 使用线性插值计算当前 x 和 y 坐标
        const currentX = start.x + (end.x - start.x) * t
        // 使用抛物线方程为 y 轴位置生成动画，增加了一个简单的抛物线效果
        const currentY = start.y + (end.y - start.y) * t - 150 * Math.sin(t * Math.PI)
        // 更新小球的位置
        ball.style.left = currentX + 'px'
        ball.style.top = currentY + 'px'
        // 如果整个动画尚未完成，则继续进行下一帧
        if (t < 1) {
          requestAnimationFrame(animate)
        } else {
          ball.style.display = 'none'
        }
      }
      // 启动动画
      requestAnimationFrame(animate)
    },
    // 购物车icon在header，从下往上缩（最开始的动画）
    handleTriggerAddCompletedAnimationTop () {
      let headerCartDom = null
      if (!headerCartDom) headerCartDom = this.isReview ? document.querySelector('.header-bag') : document.querySelector('.bsc-header-cart')
      if (!headerCartDom) headerCartDom = document.querySelector('.j-add-cart__cart-btn')
      let { x, y } = headerCartDom?.getBoundingClientRect?.() || {}
      if (x && y) {
        this.imagePosition.left = x + 12 + 'px'
        this.imagePosition.top = y + 12 + 'px'
      } else {
        this.imagePosition.left = DEFAULT_IMG_POSITION.left
        this.imagePosition.top = DEFAULT_IMG_POSITION.top
      }
      const className = 'add-completed-keyframes'
      this.$nextTick(() => {
        const element = document.querySelector(this.animationBoxClass + ' .productDetail-addCompletedImg')
        if (element) {
          element.classList.remove('mshe-hide')
          element.classList.add(className)
        } 
        setTimeout(() => {
          // 重置位置
          this.imagePosition = {
            left: '.4rem',
            top: '9.3333rem',
            minHeight: '10rem',
          }
          const element = document.querySelector(this.animationBoxClass + ' .productDetail-addCompletedImg')
          if (element) {
            element.classList.remove(className)
            element.classList.add('mshe-hide')
          } 
        }, 500)
      })
    }
  },
  render() {
    const { originImage, imagePosition, cartDynamicRedDotStyle, reddotPosition, cartBottomImgStyle } = this
    // 商品加车动画
    const renderPopAnimationBox = () => {
      if (!originImage) return null
      return (
        <img
          src={transformImg({ img: originImage })}
          class="productDetail-addCompletedImg mshe-hide"
          style={{
            ...imagePosition
          }}
        />
      )
    }
    const renderRedDotAnimationBox = () => {
      return (
        <div 
          ref="flytocart"
          class="productDetail-flytocart mshe-hide"
          style={{
            ...reddotPosition
          }}
        >
        </div>
      )
    }
    const renderImgAnimationBox = () => {
      return (      
        <img
          ref="imgAnimate"
          class="productDetail-img-animate"
          src={transformImg({ img: originImage })}
        />
      )
    }
    return cartDynamicRedDotStyle ? renderRedDotAnimationBox() : 
      cartBottomImgStyle ? renderImgAnimationBox() :
        renderPopAnimationBox()
  }
}

</script>

<style lang="less">
.productDetail-img-animate {
  position: fixed;
  z-index: 10000; /* stylelint-disable-line */
  width: 129px;
  height: 172px;
  display: none;
}
.productDetail-addCompletedImg {
  width: 10rem;
  display: block;
  position: fixed;
  top: 0;
  opacity: .5;
  left: 9.3333rem; // 这里改成购物车 left + 11px 默认 350px
  top: .4rem; // 这里改成购物车 top 默认 15px
  z-index: 10000; /* stylelint-disable-line */
  &.mshe-hide {
    display: none !important; /* stylelint-disable-line */
  }
  &.add-completed-keyframes {
      animation-name: product-detail-add-completed-transform-keyframes;
      animation-duration: 500ms;
      animation-delay: 0ms;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      transform-origin: 0 0;
  }
}
.productDetail-flytocart {
  position: fixed;
  background-color: #FE3B30;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  z-index: 1000;
}
@keyframes product-detail-add-completed-transform-keyframes {
  0% {transform: translateX(-8.6rem) scale(1) ;animation-timing-function: cubic-bezier(.25,.25,.75,.75);}
  100% {transform: translateX(0) scale(0);}
}

.low-stock-tips__addCart {
  width: 6.2rem;
  display: flex;
  flex-flow: column nowrap;
  padding: 0.32rem;
  font-family: "SF UI Text";
  font-size: 0.37333rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  & .success-tips-content {
    color: #FA6338;
    padding-bottom: 0.21rem;
    display: inline;
    .tips-icon {
      width: 0.32rem;
      height: 0.32rem;
      font-size: 0.32rem;
      margin-right: 0.05rem;
    }
    .sh_m_sui_icon_hourglass_12px_2 {
      display: inline-block;
      background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMTIgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS42MDAwNCA0LjVMNC41IDNINy41TDYuNDAwMDQgNC41SDUuNjAwMDRaIiBmaWxsPSIjRkE2MzM4Ij4KPC9wYXRoPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggOUw3IDhINUw0IDlIOFoiIGZpbGw9IiNGQTYzMzgiPgo8L3BhdGg+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMSAxSDExVjJIOS43MDAwNFYzLjI0OTg0TDcuOSA1Ljc5NTI5VjYuMTc3NUwxMC4xIDguNzIyOTZWMTBIMTFWMTFIMVYxMEgxLjkwMDAyVjguNzIyOTdMNC4wOTk5NCA2LjE3NzUxVjUuNzk1MjhMMi4zMDAwMiAzLjI0OTgzVjJIMVYxWk0yLjkwMDAyIDEwSDkuMTAwMDRWOS4wOTUyMkw2LjkgNi41NDk3N1Y1LjQ3NzQ0TDguNzAwMDQgMi45MzE5OFYySDMuMzAwMDJWMi45MzE5OUw1LjA5OTk0IDUuNDc3NDRWNi41NDk3NkwyLjkwMDAyIDkuMDk1MjFWMTBaIiBmaWxsPSIjRkE2MzM4Ij4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
      background-size: 100%;
    }
  }
}
</style>
