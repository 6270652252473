<template>
  <div 
    class="productBtnTips"
  >
    <p 
      v-if="show_sold_out_module"
      class="soldout-tip"
    >
      {{ sold_out_tip }}
      <ClientOnly v-if="find_similar_float_info.show">
        <FindSimilarFloatLayer
          :update="find_similar_float_info.show"
          :loading="find_similar_float_info.loading"
          :products="find_similar_float_info.products"
          :from="find_similar_float_info.from"
          :filterSize="find_similar_float_info.filterSize"
          :hasMoreProducts="find_similar_float_info.hasMoreProducts"

          :isSkcSoldOut="find_similar_float_box.is_skc_sold_out"
          :mainSaleText="find_similar_float_box.main_sale_text"
          :skuInfo="find_similar_float_box.sku_info"
          :baseInfo="find_similar_float_box.base_info"
          :styleConfig="find_similar_float_box.style_config"
          :texts="find_similar_float_box.texts"

          :language="language_v2"
          :detailQuickAddState="quick_add_state"
        />
      </ClientOnly>
    </p>
    <div
      v-else-if="is_customization && !isReview"
      v-expose="{
        id: '1-6-1-167',
        data: { activity_from: 'main' },
      }"
      class="customization-tip"
      @click="jumpToArticle"
    >
      <div class="customization-tip__text">
        {{ language_v2.SHEIN_KEY_PWA_26709 }}
      </div>
      <Icon
        name="sui_icon_more_right_16px_2"
        size="16px"
      />
    </div>
    <EstiamtedTag
      v-else-if="estimated_no_threshold_floor_info"
      :estimated-info="estimated_no_threshold_floor_info"
      :is-no-threshold-floor="true"
      @clickEstimatedTag="add_on_item_open"
    />
    <div
      v-else-if="show_free_shipping_tip || show_atmosphere"
      :class="{
        'freeshippingWithAtmosphere': show_free_shipping_tip && show_atmosphere,
      }"
    >
      <div 
        v-if="show_free_shipping_tip"
        v-expose="freeExpose"
        class="freeshipping"
        :style="show_atmosphere ? {} : { transform: 'translateY(6px)' }"
      >
        <Icon
          name="sui_icon_free_shipping_12px"
          size="14px"
          :is-rotate="false"
          color="#198055"
        />
        <p>{{ freeShipText }}</p>
      </div>
      <div 
        v-if="show_free_shipping_tip && show_atmosphere"
        class="freeshippingWithAtmosphere__vertical"
      ></div>
      <AtmosphereSwiper 
        v-if="show_atmosphere"
        :style="show_free_shipping_tip ? { height: '0.64rem', flex: '258' } : {}"
      />
    </div>
  </div>
</template>

<script setup>
// 定制吸底 售罄 > 定制 > 到手价 > 人气氛围 = 免邮
import { getCurrentInstance, defineAsyncComponent, computed, watch } from 'vue'
import ClientOnly from 'vue-client-only'
import { Icon } from '@shein-aidc/icon-vue2'
import {
  useMapStateV2,
  useMapGetters,
  useMapActions,
} from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import EstiamtedTag from 'public/src/pages/goods_detail_v2/innerComponents/top/Price/components/EstimatedTag/index.vue'
const AtmosphereSwiper = defineAsyncComponent(() => import(/* webpackChunkName: "AtmosphereSwiperV2" */ 'public/src/pages/goods_detail_v2/innerComponents/swipers/AtmosphereSwiper.vue'))
const FindSimilarFloatLayer = defineAsyncComponent(() => import(/* webpackChunkName: "FindSimilarFloatLayerV2" */ 'public/src/pages/goods_detail_v2/innerComponents/recommend/FindSimilarFloatLayer/index.vue'))
const instance = getCurrentInstance()
const { quick_add_state } = useMapStateV2('productDetail/addBag', ['quick_add_state'])
const { find_similar_float_info } = useMapStateV2('productDetail/Recommend', [ 'find_similar_float_info'])
const { find_similar_float_box } = useMapGetters('productDetail/Recommend', ['find_similar_float_box'])
const {
  show_sold_out_module,
  sold_out_tip,
  show_free_shipping_tip,
  show_atmosphere,
  estimated_no_threshold_floor_info
} = useMapGetters('productDetail/addBag', [
  'show_sold_out_module',
  'sold_out_tip',
  'show_free_shipping_tip',
  'show_atmosphere',
  'estimated_no_threshold_floor_info'
])

const {
  is_customization,
  language_v2,
  goods_sn,
  goods_id,
} = useMapGetters('productDetail/common', [
  'is_customization',
  'language_v2',
  'goods_id',
  'sku_info',
  'mall_code',
  'goods_sn',
  'goods_id',
])

const { 
  add_on_item_open
} = useMapActions('productDetail/common', [
  'add_on_item_open'
])
const freeShipText = computed(() => show_atmosphere?.value ? language_v2.value?.SHEIN_KEY_PWA_25388 : language_v2.value?.SHEIN_KEY_PWA_25387)
const freeExpose = {
  id: '1-6-1-154',
  data: {
    freeshipping_location: 3,
  }
}
const jumpToArticle = () => {
  const { langPath, SiteUID, IS_EUR_DATA_CENTER } = gbCommonInfo || {}
  let articleId = '1925'
  if (IS_EUR_DATA_CENTER) {
    articleId = '1819'
  }
  if (['pwus', 'rwmus'].includes(SiteUID)) {
    articleId = '1898'
  }
  instance.proxy?.$router?.push?.(`${langPath}/product/article/${articleId}`)
}

watch(show_sold_out_module, (val) => {
  if (val) {
    daEventCenter.triggerNotice({
      daId: '1-7-1-2',
      extraData: {
        sku: goods_sn.value,
        goods_id: goods_id.value,
        activeFrom: 'detail',
        code: 'goodsDetail',
        isOutOfStock: 1,
      },
    })
  }
}, {
  immediate: true,
})
</script>

<style lang="less" scoped>
.productBtnTips {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(0, -100%);

  .soldout-tip {
    padding: .213333rem .32rem;
    line-height: 1.2;
    background-color: #FFF1D5;
    .font-dpr(24px);
    color: #222;
    text-align: center;
  }

  .customization-tip {
    width: 100%;
    padding: 0.11rem 0.32rem;
    background: #FAFAFA;
    color: #767676;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__text {
      // line-height: 1;
      // font-size: 0.26667rem;
      font-size: var(--belt-text-font-size, 10px);
      font-weight: 400;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-right: 0.32rem;
    }
  }

  .freeshippingWithAtmosphere {
    display: flex;
    align-items: center;
    border-top: 1px solid #E5E5E5;
    background: rgba(255, 255, 255, 0.95);
    width: 10rem;
    max-width: 10rem;
    .freeshipping {
      flex: 115;
      border-top: none;
      background: none;
    }
  }

  .freeshippingWithAtmosphere__vertical {
    width: 1px;
    height: .4267rem;
    background: rgba(0, 0, 0, 0.3);
  }

  .freeshipping {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.95);
    height: .64rem;
    gap: .08rem;
    color: #198055;
    border-top: 1px solid #E5E5E5;
  }
}
</style>
